.breadcrumb {
  background : transparent !important;
  border     : 0 !important;
  padding    : .5rem 1rem 0 1rem !important;
}

.breadcrumbItem,
.breadcrumbItemLink {
    color       : #616161;
    font-weight : bold;
    white-space : nowrap;
}

.breadcrumbItemLink {
  text-decoration : underline;
}
