.button-toggle-filters {
  display : none;
}

@media screen and (max-width : 768px) {
  .button-toggle-filters {
    display : inline-flex;
  }

  .p-datatable-collapsible .p-datatable-thead {
    display : none;
  }

  .p-datatable-collapsible.p-datatable-filters .p-datatable-thead {
    display : flex;
  }

  .p-datatable-collapsible .p-selectable-row .p-column-hidden {
    display : none;
  }

  .p-datatable-collapsible .p-selectable-row.p-highlight .p-column-hidden {
    display : block;
  }

  .p-datatable-collapsible .p-selectable-row td:first-of-type {
    position : relative;
  }

  .p-datatable-collapsible .p-selectable-row td:first-of-type:after {
    color       : #424242;
    content     : "\e930"; /* angle down */
    float       : right;
    font-family : "primeicons";
    position    : absolute;
    bottom      : 17px;
    right       : 2%;
    font-size   : 1.25rem;
  }

  .p-datatable-collapsible .p-selectable-row.p-highlight td:first-of-type:after {
    content : "\e933"; /* angle up */
  }
}
