.table {
  border-spacing : 0;
  table-layout   : fixed;
  width          : 100%;
}

.table td,
.table th {
  border : solid 1px #EEE;
  color  : #444444;
  padding: 4px 8px;
}

.table tbody th {
  text-align: right;
  font-weight: normal;
}

.table tr:last-of-type th,
.table tr:last-of-type td {
  background-color: #EEE;
}
