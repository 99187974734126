.historyItem {
  color : var(--gray-800);
}

.historyItemSmall {
  color     : var(--gray-500);
  font-size : .8rem;
}

/* list mobile */
@media (max-width : 767px) {
  .w15 {
    display : inline-block;
    width   : 15%;
  }

  .w20 {
    display : inline-block;
    width   : 20%;
  }

  .w40 {
    display : inline-block;
    width   : 40%;
  }

  .w60 {
    display : inline-block;
    width   : 60%;
  }

  .taskTagAbbr {
    background-color           : #E9ECEF;
    border-bottom-right-radius : 4px;
    border-top-right-radius    : 4px;
    color                      : #6B6B6B;
    display                    : inline-block;
    font-weight                : bold;
    height                     : 20px;
    line-height                : 20px;
    padding                    : 0 4px;
    position                   : relative;
    text-transform             : uppercase;
  }

  .taskTagAbbr:before {
    border-bottom : solid 10px #E9ECEF;
    border-left   : solid 5px transparent;
    border-top    : solid 10px #E9ECEF;
    content       : "";
    display       : inline-block;
    left          : -5px;
    position      : absolute;
    top           : 0;
  }
}
