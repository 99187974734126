.fileContainer {
  display: flex;
  flex-wrap: wrap;
  gap: .5rem;
}

.file:hover {
    background-color : #9e9e9e;
}

.fileUploadButton {
  background-color: #E5E5E5;
  color: #9F9F9E;
  display: inline-block;
  height: 50px;
  line-height: 50px;
  margin: 2px;
  text-align: center;
  width: 50px;
}

.filesWrapper:after {
  clear: both;
  content: '';
  display: block;
}

.fileThumb,
.fileThumbLarge {
  border: solid 1px #EFEFEF;
  color: #000000;
  display: inline-block;
  margin: 2px;
  text-align: center;
  position: relative;
}

.fileThumb {
  height: 50px;
  line-height: 50px;
  width: 50px;
}

.fileThumbLarge {
  height: 80px;
  line-height: 80px;
  width: 80px;
}

.fileThumb:first-child,
.fileThumbLarge:first-child {
  margin-left: 0;
}

.fileThumbImg {
  height: 100%;
  width: 100%;
}

.deleteBtn {
  position: absolute;
  cursor: pointer;
  top: -12px;
  right: -12px;
  background-color: red;
  color: #FFF;
  width: 25px;
  height: 25px;
  line-height: 25px;
  border-radius: 50%;
  z-index: 99;
  font-size: 1.4rem;
}
