.btnBack {
  background-color : #FBC02D !important;
  border-color     : transparent !important;
  color            : #424242 !important;
}

.btnTake {
  background-color : #CED500 !important;
  border-color     : transparent !important;
  color            : #424242 !important;
}

.btnSecondary {
  background-color : #EEEEEE !important;
  border-color     : transparent !important;
  color            : #424242 !important;
}

.btnDelete {
  background-color : #424242 !important;
  border-color     : transparent !important;
  color            : #FFFFFF !important;
}

.report {
  background-color : #FAFBE5;
}

.mainInfo {
  color     : #717171;
  font-size : .75rem;
}

.description {
  background-color : #F6F7CE;
  border           : solid 1px #BFBFBF;
  border-radius    : 3px;
}

.label {
  color : #999999;
}

.badge {
  border-radius : 3px !important;
}

.accordionHeader > a {
  padding-left : 0 !important;
}

.accordionHeader > a,
.accordionContent > div {
  background : #FFFFFF !important;
  border     : 0 !important;
  display    : block;
}

.accordionContent > div {
  padding    : 0 !important;
}

.accordionHeader > a > span {
  float : right;
}

.mobileMenu {
  display  : none;
  position : relative;
}

.discussionDate {
  color : #AAAAAA;
}

.discussionText {
  background-color : #EEEEEE;
  border           : solid 1px #BFBFBF;
  border-radius    : 3px;
  margin-bottom    : 0.25rem;
  margin-top       : 4px;
  padding          : 10px 4px;
}

@media (min-width : 768px) {
  .mobileMenu {
    display : block;
  }
}

@media (max-width : 767px) {
  .mobileMenuOpen {
    background-color : black;
    border-radius    : 3px;
    display          : block !important;
    padding          : 0 4px;
    position         : absolute;
    right            : 0;
    top              : 31px;
    width            : 125px;
  }

  .mobileMenuOpen button {
    display : block;
    margin  : 4px 0;
    width   : 100%;
  }
}
