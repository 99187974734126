.dateRangeBefore,
.dateRangeIn,
.dateRangeAfter {
  border-radius    : 3px;
  display          : inline-block;
  padding          : 5px 10px;
  color            : white;
  margin: 5px 0;
}

.dateRangeBefore {
  background-color: #1769AA;
}

.dateRangeIn {
 background-color: #FF9900;
}

.dateRangeAfter {
  background-color : #FF0000;
}

.dateStartsAt,
.dateEndsAt {
  display: inline-block;
}

.dateEndsAt {
  text-align: right;
}

.sep {
  font-weight: bold;
  font-family : monospace;
  font-size: 1.25rem;
  padding: 0 5px;
}
