* {
  box-sizing  : border-box;
  font-family : Ubuntu, sans-serif;
}

a {
  text-decoration : none;
}

html, body {
  background            : url(bg.svg) top right no-repeat;
  background-attachment : fixed;
  background-size       : auto 100%;
  font-size             : 14px;
  height                : 100%;
  margin                : 0;
  padding               : 0;
}

:root {
  --success-green: #00b050;
  --partially-green: #c5e0b4;
  --failed-gray: #d0cece;
  --failed-red: #ff0000;
  --progress-red: #f4b183;
}

#root {
  min-height     : 100%;
  padding-bottom : 50px; /* footer */
  position       : relative;
}

.grid {
  margin : 0 !important;
}

.p-breadcrumb-chevron:before {
  content : "/";
}

/* change badge default value */
.p-badge {
  background-color : #9E9E9E;
}

.p-divider-content {
  font-weight : bold;
}

.p-selectbutton .p-button:not(.p-highlight) {
  opacity : .5;
}

.word-break-word {
  word-break : break-word;
}

.p-disabled {
  pointer-events : auto !important;
}

/* menu bar */
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text,
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text, .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text,
.p-menubar .p-menuitem-link .p-menuitem-text {
  color       : #FFFFFF;
  font-size   : 18px;
  font-weight : 400;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link, .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
  background : transparent;
}

.p-menubar .p-submenu-list {
  background-color : #000000;
  z-index: 2;
}
@media screen and (max-width :1200px) {
  .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }
}

@media screen and (max-width : 961px) {
  .p-menubar {
    position : sticky;
    top      : 0;
    z-index  : 10;
  }

  .p-menubar .p-menubar-root-list {
    background-color : #000000;
  }

  .p-menubar-button .pi-bars {
    font-size : 1.75rem;
  }

  .p-menubar {
    position : sticky;
    top      : 0;
    z-index  : 10;
  }

  .p-menubar .p-menubar-button,
  .p-menubar .p-menubar-button:hover {
    background : #000000;
    color      : #FFFFFF;
    order      : 2;
    margin     : 0 1rem;
  }

  .p-menubar .p-menubar-end {
    order       : 1;
    margin-left : auto;
  }
}

/* datatables */
.p-datatable .p-datatable-tbody > tr > td {
  border-bottom-width : 4px;
}

@media(max-width: 768px) {
  .p-datatable .p-datatable-tbody > tr:not(:first-of-type) > td:first-of-type {
    border-top : solid 4px #E9ECEF !important;
  }
}

.p-datatable .p-datatable-tbody > tr > td a {
  color       : #1769AA;
  font-weight : bold;
}

@media screen and (max-width : 991px) {
  .p-datatable-header {
    position : sticky;
    top      : 45px;
    z-index  : 9;
  }
}

/* forms */
.field label {
  color : #999999;
}

.field .p-inputtext.p-disabled,
.field .p-inputtextarea.p-disabled,
.field .p-dropdown.p-disabled {
  background-color : #EEEEEE;
  border-color     : #CCCCCC;
  opacity          : 1;
}

.field .p-inputtext,
.field .p-inputtextarea,
.field .p-dropdown {
  border-color : #333333;
  color        : #4C4C4C;
  font-family  : Ubuntu, sans-serif;
  font-size    : 1.15rem;
  resize       : none;
}

.p-fieldset {
  border-color : #333333;
}

/* divider */
.p-divider .p-divider-content {
  color     : #666666;
  font-size : 1.3rem;
}

.p-divider.font-normal .p-divider-content {
  font-size   : 1rem;
  font-weight : normal;
}

/* scroll panel */
.p-scrollpanel-bar {
  background : #C4C4C4 !important;
}
