.navbar {
  background    : #000000 !important;
  border        : 0 !important;
  border-radius : 0 !important;
  color         : #FFFFFF !important;
  height        : 45px;
  width         : 100%;
}

.logoBold,
.logoThin,
.envName {
  font-family : Roboto, sans-serif;
  color: #FFF;
}

.logoBold {
  font-weight  : 700;
  margin-right : 4px;
}

.logoThin {
  font-weight : 100;
}

.btnMyTasks {
  background-color : #CED500 !important;
  border-radius    : 3px;
  color            : #000000;
}

.badge {
  right : .5rem !important;
  top   : .5rem !important;
}

.envName {
  color       : red;
  margin-left : .25rem;
}
