.initials {
  background-color : #E9ECEF;
  border-radius    : 50%;
  color            : #6B6B6B;
  display          : inline-block;
  font-size        : 1.25rem;
  font-weight      : bold;
  height           : 35px;
  line-height      : 35px;
  text-align       : center;
  width            : 35px;
}
