.labelRequired:before {
  color          : red;
  content        : "*";
  font-family    : monospace;
  font-size      : 1.5rem;
  font-weight    : 900;
  line-height    : 1rem;
  vertical-align : bottom;
}

.fieldHelp {
  color     : #9e9e9e;
  font-size : .9rem;
}

.fieldError {
  color     : red;
  font-size : .9rem;
}

.emptyMessage {
  color : #666666;
}

.tableRowDanger {
  background-color : #f8b4cb !important;
}

.tableRowWarning {
  background-color : #FFE4BC !important;
}

.tableRowTPM {
  background-color : #cecece !important;
}

.tableRowZavada {
  background-color : #e9ffb0 !important;
}

/* -------------------------------------------------- */
.accordionLabelHeader>a {
  padding: 0 0 .5rem 0 !important;
  font-weight: normal !important;
}

/* outline */
.accordionLabelHeader>a:focus {
  box-shadow: none !important;
}

.accordionLabelHeader>a>span {
  float: right;
  color: #999;
}

.accordionLabelHeader>a,
.accordionLabelContent>div {
  background: #FFFFFF !important;
  border: 0 !important;
}

.accordionLabelContent>div {
  padding: 0 !important;
}
