.historyItem {
  color: #424242;
}

.historyItemSmall {
  color: #9e9e9e;
  font-size: 0.8rem;
}

.bgSuccessGreen {
  background-color: var(--success-green) !important;
}

.bgPartiallyGreen {
  background-color: var(--partially-green) !important;
}

.bgFailedGray {
  background-color: var(--failed-gray) !important;
}

.bgFailedRed {
  background-color: var(--failed-red) !important;
}

.bgProgressRed {
  background-color: var(--progress-red) !important;
}

/* list mobile */
@media (max-width: 767px) {
  .w10 {
    display: inline-block;
    width: 10%;
  }

  .w15 {
    display: inline-block;
    width: 15%;
  }

  .w20 {
    display: inline-block;
    width: 20%;
  }

  .w40 {
    display: inline-block;
    width: 40%;
  }

  .w50 {
    display: inline-block;
    width: 50%;
  }

  .w60 {
    display: inline-block;
    width: 60%;
  }

  .taskTagAbbr {
    background-color: #e9ecef;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    color: #6b6b6b;
    display: inline-block;
    font-weight: bold;
    height: 20px;
    line-height: 20px;
    padding: 0 4px;
    position: relative;
    text-transform: uppercase;
  }

  .taskTagAbbr:before {
    border-bottom: solid 10px #e9ecef;
    border-left: solid 5px transparent;
    border-top: solid 10px #e9ecef;
    content: "";
    display: inline-block;
    left: -5px;
    position: absolute;
    top: 0;
  }
}

.reportState {
  padding: 0 !important;
  height: 1px;
}

@-moz-document url-prefix() {
  .reportState {
    height: 100%;
  }
}

.reportState > div {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reportState > div > a {
  color: #fff !important;
}

.reportStateRed {
  background-color: red;
}

.reportStateGreen {
  background-color: #00b050;
}

.reportStateBlue {
  background-color: #00b0f0;
}

.reportStateOrange {
  background-color: #ffc000;
}
