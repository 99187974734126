.color {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: solid 1px #000;
  vertical-align: bottom;
}

.gt {
  font-weight: bold;
  font-family: monospace;
  font-size: 1.25rem;
  padding: 0 5px;
}
