.mentions {
  margin: 1em 0;
  z-index: 9;
}

.mentions--multiLine .mentions__control {}

.mentions--multiLine .mentions__highlighter {
  border: 1px solid transparent;
  padding: 9px;
  min-height: 75px;
}

.mentions--multiLine .mentions__input {
  border: 1px solid silver;
  padding: 9px;
  outline: 0;
}

.mentions__suggestions__list {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 10px rgba(0, 0, 0, .25);
  font-size: 10pt;
}

.mentions__suggestions__item {
  padding: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.mentions__suggestions__item--focused {
  background-color: #ededed;
}

.mention_item {
  background-color: #f0ff91;
}

.mention_item_link {
  background-color: #f0ff91;
  cursor: pointer;
}

.mention_item_link:hover {
  text-decoration: underline;
}
