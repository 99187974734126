/* copied from https://www.primefaces.org/primereact/showcase/#/datatable/responsive & modified */

.p-datatable-thead {
  position : sticky;
  top      : 0;
}

.p-datatable .p-datatable-tbody > tr > td .p-column-title {
  display : none;
}

/* filters max width */
.p-datatable .p-datatable-thead .p-dropdown,
.p-datatable .p-datatable-thead .p-inputtext,
.p-datatable .p-datatable-thead .p-calendar {
  width : 100%;
}

@media (max-width : 768px) {
  .p-datatable-thead {
    position : static;
    display: flex;
    flex-direction: column;
  }

  .p-datatable-thead > tr:first-of-type {
    order: 2;
  }

  .p-datatable-thead > tr:last-of-type {
    order: 1;
  }

  .p-datatable .p-datatable-thead > tr:first-of-type > th.p-sortable-disabled, /* header titles without sorting */
  .p-datatable .p-datatable-thead > tr:last-of-type > th:empty { /* header cell without filter */
    display : none !important;
  }

  .p-datatable .p-datatable-thead > tr > th,
  .p-datatable .p-datatable-tbody > tr > td {
    border     : 0 none;
    clear      : left;
    display    : block;
    float      : left;
    text-align : left;
    width      : 100%;
  }

  .p-datatable .p-datatable-tbody > tr > td .p-column-title {
    display : inline-block;
    width   : 40%;
    color   : #6B6B6B;
  }

  .p-datatable .p-datatable-tbody > tr > td.p-column-common .p-column-title {
    display : none;
  }
}
