.bgBlue {
    background-color : #00A6E6 !important;
    color            : #FFFFFF !important;
    font-size        : 24px !important;
    font-weight      : bold !important;
}

.bgGreen {
    background-color : #8CBD22 !important;
    color            : #FFFFFF !important;
    font-size        : 24px !important;
    font-weight      : bold !important;
}

.bgYellow {
    background-color : #CED500 !important;
    color            : #FFFFFF !important;
    font-size        : 24px !important;
    font-weight      : bold !important;
}
